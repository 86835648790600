@use '@material/fab/fab' as mdc-fab;
@use '@material/fab/fab-theme' as mdc-fab-theme;
@use '@material/fab/extended-fab-theme' as mdc-extended-fab-theme;

@use '../core/mdc-helpers/mdc-helpers';
@use '../core/style/sass-utils';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/tokens/m2/mdc/fab' as tokens-mdc-fab;
@use '../core/tokens/m2/mdc/extended-fab' as tokens-mdc-extended-fab;
@use '../core/tokens/m2/mat/fab' as tokens-mat-fab;
@use '../core/tokens/token-utils';
@use '../core/typography/typography';

@mixin base($theme) {
  // Add default values for tokens not related to color, typography, or density.
  @include sass-utils.current-selector-or-root() {
    @include mdc-fab-theme.theme(tokens-mdc-fab.get-unthemable-tokens());
    @include mdc-extended-fab-theme.theme(
      tokens-mdc-extended-fab.get-unthemable-tokens()
    );
  }
}

@mixin _fab-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-fab.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-fab.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-fab.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-fab.get-color-tokens($theme)
  );

  @include mdc-fab-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-fab.$prefix, $mat-tokens);
}

@mixin color($theme) {
  @include sass-utils.current-selector-or-root() {
    @include _fab-variant($theme, null);

    .mat-mdc-fab,
    .mat-mdc-mini-fab {
      &.mat-primary {
        @include _fab-variant($theme, primary);
      }

      &.mat-accent {
        @include _fab-variant($theme, accent);
      }

      &.mat-warn {
        @include _fab-variant($theme, warn);
      }
    }
  }
}

@mixin typography($theme) {
  @include mdc-helpers.using-mdc-typography($theme) {
    @include mdc-fab.without-ripple($query: mdc-helpers.$mdc-typography-styles-query);
  }

  $typography-tokens: tokens-mdc-extended-fab.get-typography-tokens($theme);
  @include sass-utils.current-selector-or-root() {
    @include mdc-extended-fab-theme.theme($typography-tokens);
  }
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-fab') {
    @include base($theme);
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
