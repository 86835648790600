@use 'sass:math';
@use '@material/density/functions' as mdc-density-functions;
@use '@material/icon-button/icon-button-theme' as mdc-icon-button-theme;
@use '../core/tokens/m2/mdc/icon-button' as tokens-mdc-icon-button;
@use '../core/tokens/m2/mat/icon-button' as tokens-mat-icon-button;

@use './button-base';
@use '../core/tokens/token-utils';
@use '../core/theming/theming';
@use '../core/theming/inspection';


@mixin base($theme) {
  // TODO(mmalerba): Move icon button base tokens here
}

@mixin _icon-button-variant($theme, $palette) {
  $mdc-tokens: if($palette,
    tokens-mdc-icon-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mdc-icon-button.get-color-tokens($theme)
  );

  $mat-tokens: if($palette,
    tokens-mat-icon-button.private-get-color-palette-color-tokens($theme, $palette),
    tokens-mat-icon-button.get-color-tokens($theme)
  );

  @include mdc-icon-button-theme.theme($mdc-tokens);
  @include token-utils.create-token-values(tokens-mat-icon-button.$prefix, $mat-tokens);
}

@mixin color($theme) {
  .mat-mdc-icon-button {
    @include _icon-button-variant($theme, null);

    &.mat-primary {
      @include _icon-button-variant($theme, primary);
    }

    &.mat-accent {
      @include _icon-button-variant($theme, accent);
    }

    &.mat-warn {
      @include _icon-button-variant($theme, warn);
    }
  }
}

@mixin typography($theme) {}

@mixin density($theme) {
  $icon-size: 24px;
  $density-scale: inspection.get-theme-density($theme);
  // Manually apply the expected density theming, and include the padding
  // as it was applied before
  $calculated-size: mdc-density-functions.prop-value(
    $density-config: (
      size: (
        default: 48px,
        maximum: 48px,
        minimum: 28px,
      ),
    ),
    $density-scale: $density-scale,
    $property-name: size,
  );

  // Use `mat-mdc-button-base` to increase the specificity over the button's structural styles.
  .mat-mdc-icon-button.mat-mdc-button-base {
    // Match the styles that used to be present. This is necessary for backwards
    // compat to match the previous implementations selector count (two classes).
    @include mdc-icon-button-theme.theme((
      state-layer-size: $calculated-size,
    ));

    // TODO: Switch calculated-size to "var(--mdc-icon-button-state-layer-size)"
    // Currently fails validation because the variable is "undefined"
    // in the sass stack.
    // TODO: Switch icon-size to "var(--mdc-icon-button-icon-size)". Currently
    // fails validation because the variable is "undefined" in the sass stack.
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: math.div($calculated-size - $icon-size, 2);

    @include button-base.mat-private-button-touch-target-density($density-scale);
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-icon-button') {
    @include base($theme);
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
