@import 'variables';
@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
  }

  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}
